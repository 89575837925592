import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerMembershipService {

  constructor(public api: ApiService) { }

  addPlan(data, isEdit) {
    let endpoint = 'admin/plans';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .postAsFormData(endpoint, fData)
        .map(resp => resp.json());
    }

  }
  getPlan(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/plans';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  deletePlan(id) {
    let endpoint = 'admin/plans/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  viewPlan(id) {
    let endpoint = 'admin/plans/' + id;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  updatePlanImage(data) {
    let endpoint = 'admin/plans/image';
    let fData = new FormData();
    for (var key in data) {
      fData.append(key, data[key]);
    }
    return this
      .api
      .postAsFormData(endpoint, fData)
      .map(resp => resp.json());
  }
  
    deleteProduct(pid,id) {
      let endpoint = 'admin/plans/product/' + pid + '/'+ id;
      return this
        .api
        .delete(endpoint)
        .map(resp => resp.json());
  }
  addPlanBenefit(data, isEdit, pid) {
    let endpoint = 'admin/plans/benefit/' + pid;
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  deleteBenifit(pid,id) {
    let endpoint = 'admin/plans/benefit/' + pid + '/'+ id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  addMembershipPlan(data) {
    let endpoint = 'admin/add-membership';
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
  }

  getMembershipPlan(customer_id='') {
    let endpoint = 'admin/customer-membership?';
    if (customer_id != '')
       endpoint += 'customer_id=' + customer_id
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  deleteMembership(id) {
    let endpoint = 'admin/membership/delete/'+ id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }
  addPlanProduct(data, isEdit) {
    let endpoint = 'admin/product-plans';
    if (isEdit) endpoint += '/' + data.id;
    if (isEdit) {
      return this
        .api
        .put(endpoint, data)
        .map(resp => resp.json());
    } else {
      return this
        .api
        .post(endpoint, data)
        .map(resp => resp.json());
    }
  }
  getPlanProduct(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/product-plans';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }
  deletePlanProduct(id) {
    let endpoint = 'admin/product-plans/delete/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  getSchemePoints(id){
    let endpoint='admin/customers/scheme/'+id
    return this
    .api
    .get(endpoint)
    .map(resp=>resp.json())
  }
}
