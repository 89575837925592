
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class GiftTemplateService {

  constructor(public api: ApiService) { }

  getGiftTemplates(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/template';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  getTemplatesCategory(page: number = 0, per_page: number = 0) {
    let endpoint = 'admin/template/category';
    if (page > 0)
      endpoint += '?page=' + page
    if (per_page > 0)
      endpoint += '&per_page=' + per_page;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }

  

  addTemplate(data, isEdit) {
    let endpoint = 'admin/template';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
        console.log(fData)
    }
    if (isEdit) {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    } else {
        console.log(fData)
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }

}


  addCategory(data, isEdit) {
    let endpoint = 'admin/template/category';
    if (isEdit) endpoint += '/' + data.id;
    let fData = new FormData();
    for (var key in data) {
        fData.append(key, data[key]);
    }
    if (isEdit) {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    } else {
        return this
            .api
            .postAsFormData(endpoint, fData)
            .map(resp => resp.json());
    }
  }



  

  deleteCategory(id) {
    let endpoint = 'admin/template/category/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }

  deleteTemplate(id) {
    let endpoint = 'admin/template/' + id;
    return this
      .api
      .delete(endpoint)
      .map(resp => resp.json());
  }


  showTemplate(id) {
    let endpoint = 'admin/template/' + id;
    return this
      .api
      .get(endpoint)
      .map(resp => resp.json());
  }


}
